import { useCallback, useMemo } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Link as MUILink } from '@mui/material';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Icon, { IconName } from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

export const LINK_SIZES = ['xs', 'small', 'medium'];
type LinkSize = (typeof LINK_SIZES)[number];

interface LinkProps {
  /** If allowWrap is set to true, text will wrap with appropriate line-heights
   *  and icons will not render
   */
  allowWrap?: boolean;
  children: string;
  id?: string;
  className?: string;
  href?: string;
  linkTo?: string;
  disabled?: boolean;
  onClick?: (...args: any[]) => void;
  size?: LinkSize;
  target?: '_blank' | '_parent' | '_top';
  startIcon?: IconName;
  endIcon?: IconName;
  dataTestId?: string;
  uxElement?: string;
}

const textVariants = (size: string) => {
  switch (size) {
    case 'xs':
      return 'captionBold';
    case 'small':
      return 'bodySmBold';
    case 'medium':
      return 'bodyBold';
    default:
      return 'bodyBold';
  }
};

const LinkComponent = ({
  children,
  size = 'medium',
  href,
  target,
  linkTo,
  onClick,
  startIcon,
  endIcon,
  id,
  dataTestId,
  uxElement,
  className,
  allowWrap = false,
  cx,
}: LinkProps & CxProps) => {
  const trackUx = useTrackUx(
    useMemo(
      () => ({
        linkText: children,
        element: uxElement,
        size,
        url: href || linkTo,
      }),
      [children, uxElement, size, href, linkTo]
    ) as any
  );

  const handleClick = useCallback(
    event => {
      if (uxElement) {
        trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK);
      }
      onClick?.(event);
    },
    [onClick, trackUx, uxElement]
  );

  return (
    <MUILink
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      id={id}
      href={href}
      onClick={handleClick}
      underline="none"
      className={`${className} ${cx({
        [size]: true,
        'allow-wrap': allowWrap,
      })}`}
      data-testid={dataTestId}
      component={linkTo && RRLink}
      to={linkTo}
      role="link"
    >
      {startIcon && !allowWrap && (
        <Icon
          size="small"
          iconName={startIcon}
          vcenter
          mr={4}
          component="span"
        />
      )}
      <Text variant={textVariants(size)} component="span">
        {children}
      </Text>
      {endIcon && !allowWrap && (
        <Icon size="small" iconName={endIcon} vcenter ml={4} component="span" />
      )}
    </MUILink>
  );
};

export default withCx<LinkProps>('FDBLink')(LinkComponent);
