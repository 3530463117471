import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgShow = ({
  alt = 'Show Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M15.533 10.732a5.303 5.303 0 0 1 5.8 5.311A5.335 5.335 0 0 1 16 21.376a5.303 5.303 0 0 1-5.311-5.8c.517.25 1.117.467 1.755.467A3.559 3.559 0 0 0 16 12.488c0-.64-.217-1.24-.467-1.756ZM26.7 8.076c2.6 2.412 4.339 5.25 5.161 7.284.183.439.183.928 0 1.366C31.04 18.71 29.3 21.55 26.7 24.01c-2.617 2.433-6.211 4.477-10.7 4.477-4.489 0-8.083-2.044-10.699-4.477-2.6-2.461-4.338-5.3-5.164-7.284a1.776 1.776 0 0 1 0-1.366C.963 13.326 2.7 10.488 5.3 8.076 7.917 5.646 11.511 3.6 16 3.6s8.083 2.046 10.7 4.477ZM16 8.043c-4.417 0-8 3.583-8 8s3.583 8 8 8 8-3.583 8-8-3.583-8-8-8Z"
    />
  </svg>
);
export default SvgShow;
