import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgFrown = ({
  alt = 'Frown Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M16 21.944c-2.325 0-3.95 1.15-4.9 2.175a1.5 1.5 0 0 1-2.119.087c-.662-.562-.643-1.512-.081-2.119 1.369-1.493 3.744-3.143 7.1-3.143 3.356 0 5.731 1.65 7.1 3.143a1.5 1.5 0 0 1-.081 2.12 1.5 1.5 0 0 1-2.119-.088c-.95-1.025-2.575-2.175-4.9-2.175ZM13.025 13c0 1.106-.9 2-2 2-1.106 0-2-.894-2-2 0-1.106.894-2 2-2 1.1 0 2 .894 2 2Zm6 0c0-1.106.894-2 2-2 1.1 0 2 .894 2 2 0 1.106-.9 2-2 2-1.106 0-2-.894-2-2ZM32 16c0 8.837-7.163 16-16 16-8.838 0-16-7.163-16-16C0 7.162 7.162 0 16 0c8.837 0 16 7.162 16 16ZM16 3C8.819 3 3 8.819 3 16s5.819 13 13 13 13-5.819 13-13S23.181 3 16 3Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFrown;
