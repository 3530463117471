import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgHide = ({
  alt = 'Hide Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M7.535 7.839C9.75 6.114 12.59 4.8 16 4.8c4.04 0 7.275 1.842 9.63 4.03 2.34 2.17 3.905 4.725 4.645 6.555a1.59 1.59 0 0 1 0 1.23c-.67 1.615-2.015 3.89-3.995 5.92l5.26 4.12a1.201 1.201 0 0 1-1.48 1.89L.46 5.345a1.2 1.2 0 1 1 1.48-1.89L7.535 7.84Zm3.62 2.836 4.515 3.54c.21-.425.33-.905.33-1.46 0-.53-.195-1.07-.42-1.535.14-.015.28-.065.42-.065 2.65 0 4.8 2.15 4.8 4.8 0 .73-.145 1.38-.445 1.97l1.975 1.51a7.21 7.21 0 0 0 .87-3.48c0-3.93-3.225-7.2-7.2-7.2-1.865 0-3.57.755-4.845 1.92ZM16 27.2c-4.04 0-7.275-1.84-9.63-4.03-2.339-2.215-3.903-4.77-4.647-6.555a1.599 1.599 0 0 1 0-1.23c.477-1.145 1.291-2.625 2.431-4.11L8.87 14.99c-.045.33-.07.665-.07.965 0 4.02 3.225 7.245 7.2 7.245.935 0 1.83-.18 2.65-.505l3.66 2.88c-1.815.98-3.92 1.625-6.31 1.625Z"
    />
  </svg>
);
export default SvgHide;
